import st from './Guide.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import {Trans, useTranslation} from "react-i18next"
import { i18TagMap } from '../../helpers/helpers'
import { useDispatch } from 'react-redux'
import { setRefBlock } from '../../store/actions/baseActions'
import useNav from '../../hooks/useNav'
import { memo, useEffect, useRef } from 'react'

const Guide = memo(({ type }) => {
	const dispatch = useDispatch()
	const { location } = useNav()
	const jackpotRef = useRef()
	const { t } = useTranslation()

	useEffect(() => {
		if (location.hash === '#jackpot') {
			jackpotRef.current.scrollIntoView({
				behavior: 'smooth',
			})
		}
	}, [location.hash])

	return (
		<div className={cx(st.guide, type)}>
			<div className={st.guideBlock}>
				<h3>{t('common.game_name')}</h3>
				<Trans
					i18nKey={'translation:messages.guide.place_bet.head'}
					components={i18TagMap()}
				>
					<h5>ბილეთების განთავსება</h5>
					<p>ტირაჟზე ბილეთის განთავსება შესაძლებელია ორი გზით:</p>
				</Trans>
				<Trans
					i18nKey={'translation:messages.guide.place_bet.list'}
					components={i18TagMap()}
				>
					<ul>
						<li>
							სტანდარტული ბილეთი - ფსონები მიიღება მთავარ ბალანსზე
							არსებული თანხით
						</li>
						<li>
							FREESPIN ბილეთი - ფსონები მიიღება FREESPIN ბალანსზე
							არსებული თანხით
						</li>
					</ul>
				</Trans>
			</div>
			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.limits.body'}
					components={i18TagMap()}
				>
					<h5>ლიმიტები</h5>
					<p>ვოლტში მოქმედებს შემდეგი შეზღუდვები:</p>
					<ul>
						<li>მინიმალური ფსონი 1 ბილეთზე: 0.10 ლარი</li>
						<li>ბილეთების მაქსიმალური რაოდენობა 1 ტირაჟზე: 10 ბილეთი</li>
						<li>
							მაქსიმალური ჯამური ფსონი ერთ მომხმარებელზე თითოეულ ტირაჟზე:
							1000 ლარი
						</li>
						<li>
							მაქსიმალური მოგება ერთ მომხმარებელზე თითოეულ ტირაჟზე: 100
							000 ლარი
						</li>
					</ul>
				</Trans>
			</div>
			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.win.body'}
					components={i18TagMap()}
				>
					<h5>მოგების დარიცხვა</h5>
					<p>მოგების დარიცხვა ხდება შემდეგი პრინციპით:</p>
					<ul>
						<li>
							<b>სტანდარტული ბილეთი</b> - ტირაჟზე მოგებული თანხა სრულად
							აისახება ბალანსზე
						</li>
						<li>
							<b>FREESPIN ბილეთი</b> - ტირაჟზე მოგებული თანხა ბალანსზე
							აისახება ფსონის გამოკლებით. მაგალითად, თუ უფასო ფსონით
							განთავსდა 5 ლარიანი ბილეთი და მომხმარებელმა მოიგო 50 ლარი,
							ბალანსზე დაერიცხება 50 – 5 = 45 ₾
						</li>
					</ul>
				</Trans>
			</div>
			<div
				className={st.guideBlock}
				ref={(ref) => {
					jackpotRef.current = ref
					dispatch(
						setRefBlock({
							id: 'guideJackpotBlock',
							value: ref,
						}),
					)
				}}
			>
				<Trans
					i18nKey={'translation:messages.guide.jackpot.body'}
					components={i18TagMap()}
				>
					<h5>ჯეკპოტი</h5>
					<p>ჯეკპოტის სხვადასხვა დონე თამაშდება შემდეგი წესით:</p>
					<ul>
						<li>
							<b>KILOWATT:</b> 10 000-დან 30 000 ვოლტამდე
						</li>
						<li>
							<b>MEGAWATT:</b> 30 000-დან 100 000 ვოლტამდე
						</li>
						<li>
							<b>GIGAWATT:</b> 100 000 ვოლტიდან ზემოთ
						</li>
					</ul>
					<p>
						ჯეკპოტებში გათამაშებული თანხა ნაწილდება ფსონების პროპორციულად
					</p>
					<p>
						ჯეკპოტის გათამაშებაში მონაწილეობისთვის აუცილებელია ბილეთი
						აკმაყოფილებდეს შემდეგ პირობებს:
					</p>
					<ul>
						<li>
							მინიმალური ფსონი: <span>1 ლარი</span>
						</li>
						<li>
							მინიმალური განაღდების კოეფიციენტი: <span>2.0 ვოლტი</span>
						</li>
					</ul>
					<p>
						<u>ჯეკპოტის გათამაშებაში არ მონაწილეობს</u>:
					</p>
					<ul>
						<li>
							ბილეთები <span> 1 ლარამდე ფსონით</span>
						</li>
						<li>
							ბილეთები რომლებიც განაღდებულია <span>2.0 ვოლტამდე</span>
						</li>
						<li>FREEVOLT ბილეთები</li>
					</ul>
				</Trans>
			</div>
			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.check_edition.body'}
					components={i18TagMap()}
				>
					<h5>ტირაჟის გადამოწმება</h5>
					<p>
						ტირაჟის გადამოწმება საშუალებას გაძლევთ დარწმუნდეთ, რომ ტირაჟში
						„ჩაწვის“ წერტილი წინასწარ არის განსაზღვრული და არ არის
						დამოკიდებული მომხმარებლების ფსონზე ამ რაიმე სხვა ფაქტორზე.
					</p>
					<p>
						სერვერზე წინაწარ არის შედგენილი მომავალი ტირაჟების სია და
						თამაშის პროცესი მიყვება მას რიგითობით. მომავალი ტირაჟების
						შედეგები არ ითვლება მიმდინარე რეჟიმში, რაც გამორიცხავს იმ
						მოსაზრებას, რომ სერვერის მხრიდან ტირაჟის ჩაწვის წერტილის
						გამოთვლა ხდება მომხმარებლის ქცევის გათვალიწინებით.
					</p>
					<p>
						თითოეული ტირაჟის სანდოობის კოდი შეიცავს 3 ძირითად პარამეტრს,
						რომლითაც იქმნება ტირაჟის გასაღები. ეს პარამეტრებია:
					</p>
					<ul>
						<li>ტირაჟის ნომერი</li>
						<li>ჩაწვის წერტილი</li>
						<li>ტირაჟის უნიკალური სერიული ნომერი</li>
					</ul>
					<p>
						ტირაჟის გასაღები წარმოადგენს ამ სამი პარამეტრის და სიტყვა{' '}
						<code>VOLT</code>-ის ტექსტურ გაერთიანებას.
					</p>
					<p>
						მაგალითად, თუ ტირაჟის ნომერი არის <code>123456</code>, ხოლო
						ტირაჟის ჩაწვის წერტილი -<code>4.65</code> და ტირაჟის უნიკალური
						ნომერი
						<code>a1b2c3d4e5f6g7h8i9j0klmnop</code>, მაშინ ტირაჟის
						გასაღებს ექნება შემდეგი სახე:
					</p>
					<center>
						<code>123456_4.65_VOLT_a1b2c3d4e5f6g7h8i9j0klmnop</code>
					</center>
					<p>
						თამაშის გვერდზე, „ტირაჟის შემოწმების“ ჩანართში მოცემულია
						გასული, მიმდინარე და მომავალი ტირაჟების სია. გასულ ტირაჟებზე
						ჩანს, როგორც გასაღები ასევე „სანდოობის კოდი“. მომავალ და
						მიმდინარე ტირაჟზე ჩანს მხოლოდ სანდოობის კოდი.
					</p>
					<p>
						სანდოობის კოდი წარმოადგენს <code>SHA256</code> ალგორითმით
						დაშიფრულ ტირაჟის გასაღებს. აღნიშნული ალგორითმით დაშიფრული
						გასაღები უნიკალურია და შეუძლებელია 2 სხვადასხვა გასაღებს
						ქონდეს ერთი და იგივე შიფრი. შესაბამისად თუ მოთამაშისათვის
						წინასწარ არის ცნობილი გასაღების შიფრი, ტირაჟის შედეგის შეცვლა
						ისე, რომ შიფრი არ შეიცვალოს, შეუძლებელია.
					</p>
					<p>
						დავუშვათ, ტირაჟის შემოწმების გვერდზე ვხედავთ მომავლი ტირაჟების
						ჩამონათვალს, რომელთაგან ერთერთი ტირაჟის სანდოობის კოდი არის:
					</p>
					<center>
						<code>
							10cfa29a0b183c8ac74959b9aa0add2f1e9224c147ef400f7f57e8266e5bb114
						</code>
					</center>
					<p>
						ველოდებით აღნიშნული ტირაჟზე გასაღების გამოჩენას. აღნიშნული
						გასაღები
						<code>SHA256</code> ალგორითმის გადაშიფრვით მიიღებთ წინასწარ
						ცნობილ სანდოობის კოდს.
					</p>
					<p>
						იმისათვის, რათა გაგიმარტივდეთ აღნიშნული პროცესი, შეგიძლიათ
						გამოიყენოთ ონლაინ <code>SHA256</code>
						კალკულატორები. მაგალითად:
					</p>
					<ul>
						<li>
							<a
								href="https://xorbin.com/tools/sha256-hash-calculator"
								target="_blank"
								rel="noreferrer"
							>
								https://xorbin.com/tools/sha256-hash-calculator
							</a>
						</li>
						<li>
							<a
								href="https://md5calc.com/hash/sha256"
								target="_blank"
								rel="noreferrer"
							>
								https://md5calc.com/hash/sha256
							</a>
						</li>
					</ul>
				</Trans>
			</div>
		</div>
	)
})

Guide.propTypes = {
	type: PropTypes.string,
}

export default Guide
