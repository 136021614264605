import { memo, useCallback, useEffect, useRef, useState } from 'react'
import st from './Header.module.scss'
import {
	callPostMessage,
	convertCoin,
	thousandSeparator,
} from '../../helpers/helpers'
import PropTypes from 'prop-types'
import cx from 'classnames'
import usePrevious from '../../hooks/usePrevious'
import { CSSTransition } from 'react-transition-group'
import CountUp from 'react-countup'
import { PMEndpoints } from '../../store/types'

const Balance = memo(({ title, value, device, type }) => {
	const preVal = usePrevious(value)
	const [animateBalance, setAnimateBalance] = useState(false)
	const [changeMode, setChangeMode] = useState('inc')
	const [width, setWidth] = useState(0)
	const balRef = useRef(null)
	const balanceDigitFormatter = useCallback((num) => {
		return thousandSeparator((Math.floor(num * 100) / 100).toFixed(2))
	}, [])

	useEffect(() => {
		if (typeof preVal !== 'undefined') {
			if (preVal < value) {
				setChangeMode('inc')
				setAnimateBalance(true)
			} else if (preVal > value) {
				setChangeMode('dec')
				setAnimateBalance(true)
			}
		}
	}, [preVal, value])

	useEffect(() => {
		if (typeof value === 'number') {
			if (device === 'mob') {
				setWidth((value.toFixed(2).length - 1) * 6 + 37)
			} else {
				setWidth((value.toFixed(2).length - 1) * 10 + 40)
			}
		}
	}, [device, value])

	return (
		<CSSTransition
			nodeRef={balRef}
			in={animateBalance}
			timeout={300}
			classNames={changeMode === 'inc' ? 'bal-inc' : 'bal-dec'}
			onEntered={() => {
				setAnimateBalance(false)
			}}
		>
			<div
				className={cx(st.balance, {
					[st.mobile]: device === 'mob',
					[st.freevolt]: type === 'virtual',
				})}
				ref={balRef}
				style={{ width }}
				onClick={() => {
					if (type === 'real') {
						callPostMessage(PMEndpoints.CASHIER)
					}
				}}
			>
				<div className={st.title}>{title}</div>
				<div className={st.money}>
					<CountUp
						end={parseFloat(convertCoin(value))}
						decimals={10}
						duration={0.3}
						decimal={'.'}
						formattingFn={balanceDigitFormatter}
						preserveValue={true}
					/>
					<i className="icon-gel" />
				</div>
			</div>
		</CSSTransition>
	)
})

Balance.propTypes = {
	title: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	device: PropTypes.oneOf(['web', 'mob']),
	type: PropTypes.oneOf(['real', 'virtual']),
}

Balance.defaultProps = {
	device: 'web',
	type: 'real',
}

export default Balance
